import { Modal, TextContainer } from "@shopify/polaris"
import React, { createContext, useContext, useState } from "react"
import useTranslateFunction from "./useTranslateFunction"

interface PromptContextType {
  openPrompt: (value: {
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
    primaryActionText?: string
    secondaryActionText?: string
    destructive?: boolean
    hideCancelButton?: boolean
  }) => void
}

const PromptContext = createContext<PromptContextType>({
  openPrompt: () => {
    alert("Error: Context is not giving the open prompt. Wrap it further down.")
  },
})

export function PromptProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const [promptDetails, setPromptDetails] = useState<{
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
    primaryActionText?: string
    secondaryActionText?: string
    destructive?: boolean
    hideCancelButton?: boolean
  }>({
    title: "",
    description: "",
    onConfirm: () => {},
  })
  const { t } = useTranslateFunction()

  function openPrompt(value: {
    title: string
    description: string | React.ReactNode
    onConfirm: () => void
    onCancel?: () => void
  }) {
    setPromptDetails(value)
    setIsOpen(true)
  }

  return (
    <PromptContext.Provider value={{ openPrompt }}>
      {children}
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={promptDetails.title}
        primaryAction={{
          content: promptDetails.primaryActionText || t("usePrompt_Confirmar"),
          onAction: () => {
            promptDetails.onConfirm()
            setIsOpen(false)
          },
          destructive: promptDetails.destructive,
        }}
        secondaryActions={
          promptDetails.hideCancelButton
            ? []
            : [
                {
                  content:
                    promptDetails.secondaryActionText ||
                    t("usePrompt_Cancelar"),
                  onAction: () => {
                    promptDetails.onCancel && promptDetails.onCancel()
                    setIsOpen(false)
                  },
                },
              ]
        }
      >
        <Modal.Section>
          <div>{promptDetails.description}</div>
        </Modal.Section>
      </Modal>
    </PromptContext.Provider>
  )
}

export function usePrompt() {
  return useContext(PromptContext)
}
