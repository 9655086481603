/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import useTranslateFunction from "@hooks/useTranslateFunction"

const CONFIRMAFY_PHONE_NUMBER = "15144466058"

export function getWhatsAppUrl(message: string) {
  return `https://wa.me/${CONFIRMAFY_PHONE_NUMBER}?text=${message}`
}

export function WhatsAppLink({ message, ...props }: any) {
  return (
    <a
      onClick={() => window.open(getWhatsAppUrl(message), "_blank")}
      href="#"
      {...props}
    ></a>
  )
}

export function WhatsAppWidget({
  buttonText,
  message,
  fixedLeft = false,
  notFixed = false,
}: {
  buttonText?: string
  message?: string
  fixedLeft?: boolean
  notFixed?: boolean
}) {
  const { t } = useTranslateFunction()

  buttonText = buttonText || t("whatsAppWidget_¿Tienes dudas?")
  message =
    message || t("whatsAppWidget_Hola, quiero conocer más sobre Confirmafy.")

  function onClick() {
    window.open(getWhatsAppUrl(message), "_blank")
  }

  return (
    <div
      className={`${notFixed ? "" : "fixed inline-flex"} bottom-0 ${
        fixedLeft ? "-left-6" : "-right-6"
      }  z-10 mb-4 mr-2 scale-75 cursor-pointer rounded border bg-white px-2 py-2 shadow-xl`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img className="w-8" src="/whatsapp.png" alt="" />
        <div className="ml-2 text-sm">{buttonText}</div>
      </div>
    </div>
  )
}

export function WhatsAppWidgetPositonAbsolute({
  message,
}: {
  message?: string
}) {
  const { t } = useTranslateFunction()
  message =
    message || t("whatsAppWidget_Hola, quiero conocer más sobre Confirmafy.")

  function onClick() {
    window.open(getWhatsAppUrl(message), "_blank")
  }

  return (
    <button
      className={`absolute bottom-0 -right-6 z-10 mb-4 mr-2 scale-75 cursor-pointer rounded border bg-white px-2 py-2 shadow-xl`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img className="w-8" src="/whatsapp.png" alt="" />
        <div className="ml-2 text-sm">
          {t("whatsAppWidget_¿Necesitas ayuda?")}
        </div>
      </div>
    </button>
  )
}
